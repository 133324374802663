<template>
    <div class="ticket">
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">充值记录</div>
            <div class="toolBar">
                <h4 style="padding: 0 10px">充值日期</h4>
                <el-date-picker
                    v-model="date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                ></el-date-picker>
                <el-button
                    class="red"
                    @click="handleQueryTable()"
                    style="margin: 0 10px; height: 40px"
                >
                    查询
                </el-button>
            </div>
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                height="calc(86vh - 250px)"
                style="width: calc(100% - 40px)"
            >
                <el-table-column
                    prop="orderNo"
                    label="订单编号"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="goodsPrice"
                    label="充值金额(元)"
                    align="center"
                >
                    <template slot-scope="scope">
                        ￥{{ scope.row.goodsPrice / 100 }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="amount"
                    label="到账金额(火币)"
                    align="center"
                >
                    <template slot-scope="scope">
                        <img src="@/assets/img/coin.png" width="15" alt="" />
                        {{
                            scope.row.amount / payRate +
                            scope.row.giveAmount / payRate
                        }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="payType                    "
                    label="支付方式"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-tag
                            type="success"
                            v-if="scope.row.payType === 'WXPAY'"
                        >
                            微信支付
                        </el-tag>
                        <el-tag type="primary" v-else-if="scope.row.payType==='ALIPAY'">支付宝支付</el-tag>
                        <el-tag v-else type="danger">对公转账</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="充值时间"
                    align="center"
                ></el-table-column>
                <el-table-column prop="status" label="订单状态" align="center">
                    <template slot-scope="scope">
                        <el-tag :type="payStatusCheck(scope.row.status)[1]">
                            {{ payStatusCheck(scope.row.status)[0] }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="statusResult"
                    label="原因"
                    align="center"
                ></el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                    v-if="totalNum > 0"
                ></el-pagination>
            </div>
        </el-card>
    </div>
</template>

<script>
import { getRechargeList } from "@/api/pay";
import Moment from "moment";
import PayMentDetailItem from "./PayMentDetailItem.vue";
import {
    ticketInfoSetting,
    ticketStatus,
    ticketClass,
    payRate,
} from "@/utils/pay-data-list";
export default {
    components: { PayMentDetailItem },
    data() {
        return {
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            date: "",
            table: [],
            ticketDialogVisible: false,
            ticketLookDialogVisible: false,
            ticketInfoSetting: ticketInfoSetting,
            form: {
                ...ticketInfoSetting.map((item) => item.name),
            },
            lastForm: { ...ticketInfoSetting.map((item) => item.name) },
            type: 1,
            companyName: "",
            tableData: [],
            rules: {},
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
            tableLoading: false,
            payStatus: {
                NOTPAY: ["未支付", "info"],
                CLOSED: ["交易关闭", "danger"],
                SUCCESS: ["支付成功", "success"],
            },
            payRate,
        };
    },
    computed: {},
    methods: {
        payStatusCheck(item) {
            return this.payStatus[item];
        },
        // 获取默认时间
        defaultTime() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            this.date = [start, end];
        },
        // 根据类型筛选
        filterToValue(ele) {
            return ele.map((item, index) => ({
                text: item.name,
                value: index,
            }));
        },
        // 筛选
        filterHandler(value, row, column) {
            const property = column["property"];
            return row[property] === value;
        },
        openTicketInfoDialog() {
            if (this.lastForm.companyName) {
                this.ticketDialogVisible = true;
                return;
            }
            getCompanyInvoiceByCompanyId().then(({ data }) => {
                this.form = data.data;
                this.lastForm = data.data;
                this.ticketDialogVisible = true;
            });
        },
        handleLookDetail(id) {
            getCompanyInvoiceRecordDetails({ id }).then(({ data }) => {
                console.log(data);
                this.ticketLookDialogVisible = true;
            });
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.handleQueryTable();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.handleQueryTable();
        },
        handleQueryTable() {
            const distanceDate = Moment(this.date[1]) - Moment(this.date[0]);
            const perDayTime = 3600 * 1000 * 24;
            if (distanceDate / perDayTime > 90) {
                this.$message.error("最大时间间隔不能超过90天");
                return;
            }
            const param = {
                startTime:
                    Moment(this.date[0]).format("yyyy-MM-DD") + " 00:00:00",
                endTime:
                    Moment(this.date[1]).format("yyyy-MM-DD") + " 23:59:59",
                pageSize: this.pageSize,
                pageNo: this.pageNo,
            };
            this.tableLoading = true;
            getRechargeList(param)
                .then(({ data }) => {
                    this.tableData = data.data.list;
                    this.totalNum = data.data.total;
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },
    },
    mounted() {
        this.defaultTime();
        this.handleQueryTable();
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.detailHeader {
    line-height: 36px;
    font-weight: 600;
    font-size: 16px;
    padding-left: 20px;
    background: #f2f2f2;
    border-left: 4px solid var(--RED-C11C20);
}
.red {
    background-color: var(--RED-C11C20);
    color: white;
}

.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 100px 0px;
}

.ticket{
    min-width:800px;
}

.toolBar {
    display: flex;
}
</style>
